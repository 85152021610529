@font-face {
  font-family: dotGothic;
  src: url(DotGothic16-Regular.ttf);
}

@font-face {
  font-family: dosis;
  src: url(Dosis-Regular.ttf);
}

.storypanel {
  position: absolute;
  z-index: 50;
  bottom: 5vh;
  left: 5vw;
  width: 400px;
  background:rgba(79, 79, 79,.3);
  min-height: 200px;
  display: flex;
  flex-direction:column;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0,0,0,.15);
  font-family: avenir next,avenir,sans-serif !important;
  color: rgb(226, 226, 226);
  text-align: left;
  transition: all 1s ease-in-out;
}


.storypanel:hover {
  transition: all 1s ease-in-out;
  background:rgba(79, 79, 79,.9);
}
.projectHeader{
  font-weight:bold;
  margin: 10px;
  align-items: center;
  font-family: dotGothic;
  letter-spacing: .15em;
}

.projectList{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.projectBody{
  max-height :0px;
  transition: all 1s ease-in-out;
  background: rgba(33, 33, 33,.3);
  overflow: hidden;
  border-top: 10px;
  box-sizing: border-box;
  font-family: dosis;
}

.projectBodyText{
  padding: 10px;
}

.project:hover .projectBody{
  max-height: 100px;
  transition: all 1s ease-in-out;
}
.project {
  transition: all 1s ease-in-out;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(145, 145, 145,.5); 
  align-items: flex-start;
  justify-content: center;
}
.project:hover {
  transition: all 1s ease-in-out;
  border-bottom: 1px solid rgb(38, 38, 38,.15); 
}
.last {
  border-bottom: 0px solid rgb(0,0,0,0); 
}

.projectHeader:hover .projectBody{
  display: flex;
}

.header{
  font-size: 24px;
  font-weight: bolder;
  background: rgba(0,0,0,.3);
  padding: 10px;
  font-family: dotGothic;
}

.hideVid{
  position: fixed;
  transition: all 1s ease-in-out;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  background: black;
}
.showVid {
  position: absolute;
  transition: all 1s ease-in-out;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%  ;
  z-index: 49;
  opacity: 1;
  background: black;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.videoh {
  object-fit: fill;
}